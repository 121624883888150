<template>
  <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
    <HelloWorld/>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import HelloWorld from './components/HelloWorld.vue';

@Options({
  components: {
    HelloWorld,
  },
})
export default class App extends Vue {
}
</script>
