import axios from "axios";
import Counter from "@/components/Counter";

class CounterService {
    async FetchCounter(): Promise<Counter> {
        try {
            const response = await axios.get<Counter>(`https://mowee-demoservice.herokuapp.com/helloWorld`);

            return response.data
        } catch (error) {
            console.log(error);
            throw error
        }
    }
}

export default new CounterService();