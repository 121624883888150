<template>
  <div>
    <div>
      Counter:
      <span v-if="counter">{{ counter.counter }}</span>
      <span v-else>0</span>
    </div>
  </div>
</template>

<script lang="ts">
import {Vue} from 'vue-class-component';
import Counter from "@/components/Counter";
import CounterService from "@/components/CounterService";

export default class HelloWorld extends Vue {
  private counter?: Counter

  data() {
    return {
      counter: null
    }
  }

  async mounted() {
    let counter: Counter;
    counter = await CounterService.FetchCounter();
    this.counter = counter
  }
}
</script>
